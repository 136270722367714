import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EBaseUrl } from "index";
import thunkOptions from "store/config/thunkOptions";
import {
  IAddCampaignTranslation,
  ICampaignTranslation,
  ICampaignTranslationInitialState,
  IDeleteCampaignTranslation,
  IMakeCampaignTranslationVisible,
  ITranslationLanguage,
} from "store/interfaces/campaignTranslation";
import { IPaginated, IState } from "store/interfaces/main";
import { api } from "../services/apiService";

const name = "CAMPAIGNS";

const initialState: ICampaignTranslationInitialState = {
  gridData: null,
  languages: [],
};

export const GetCampaignTranslationGrid = createAsyncThunk<
  IPaginated<ICampaignTranslation>,
  string
>(
  `${name}/GetCampaignTranslationGrid`,
  async (id) => {
    return (
      await api.get(
        `${EBaseUrl.API}/Campaign/CampaignTranslationOverview/${id}`
      )
    ).data;
  },
  thunkOptions
);

export const GetTranslationLanguages = createAsyncThunk<ITranslationLanguage[]>(
  `${name}/GetTranslationLanguages`,
  async (id) => {
    return (
      await api.get(`${EBaseUrl.API}/Campaign/GetCampaignTranslationLanguages`)
    ).data;
  },
  thunkOptions
);

export const AddTranslationForCampaign = createAsyncThunk<
  any,
  IAddCampaignTranslation
>(
  `${name}/AddTranslationForCampaign`,
  async (body) => {
    return (
      await api.post(`${EBaseUrl.API}/Campaign/AddCampaignTranslation`, body)
    ).data;
  },
  thunkOptions
);

export const DeleteCampaignTranslation = createAsyncThunk<
  any,
  IDeleteCampaignTranslation
>(
  `${name}/DeleteCampaignTranslation`,
  async ({ id, languageID }) => {
    return (
      await api.delete(
        `${EBaseUrl.API}/Campaign/DeleteCampaignTranslation/${id}/${languageID}`
      )
    ).data;
  },
  thunkOptions
);

export const MakeCampaignTranslationVisible = createAsyncThunk<
  any,
  IMakeCampaignTranslationVisible
>(
  `${name}/MakeCampaignTranslationVisible`,
  async ({ campaignId, languageId }) => {
    return (
      await api.put(
        `${EBaseUrl.API}/Campaign/ChangeCampaignTranslationVisabilityForRespondents?LanguageId=${languageId}&CampaignId=${campaignId}`
      )
    ).data;
  },
  thunkOptions
);

const campaignTranslationSlicer = createSlice({
  initialState,
  name,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      GetCampaignTranslationGrid.fulfilled,
      (state, { payload }) => {
        state.gridData = payload;
      }
    );
    builder.addCase(GetTranslationLanguages.fulfilled, (state, { payload }) => {
      state.languages = payload;
    });
  },
});

export const selectCampaignTranslations = (state: IState) =>
  state.campaignTranslation.gridData;

export const selectTranslationLanguages = (state: IState) =>
  state.campaignTranslation.languages;

export default campaignTranslationSlicer.reducer;
