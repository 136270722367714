import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import AddCampaignTranslationLanguage from "shared/components/AddCampaignTranslationLanguage";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import useTranslation from "shared/helpers/hooks/useTranslation";
import { requiredRules } from "shared/helpers/validators";
import ButtonLoader from "shared/ui/ButtonLoader";
import InputField from "shared/ui/TextInput";
import { ERequestStatus } from "store/enums/index.enum";
import { ITranslationLanguage } from "store/interfaces/campaignTranslation";
import { AddTranslationForCampaign } from "store/slicers/campaignTranslations";
import {
  selectButtonLoadingState,
  setButtonLoading,
} from "store/slicers/common";

interface IFormValues {
  language: ITranslationLanguage | null;
}

const AddCampaignTranslation = ({ campaignID, onAddSuccess }) => {
  const t = useTranslation();
  const buttonLoading = useSelector(selectButtonLoadingState);
  const dispatch = useAsyncDispatch();
  const methods = useForm<IFormValues>({
    defaultValues: {
      language: null,
    },
  });

  const onSubmit = async (formData: IFormValues) => {
    await dispatch(setButtonLoading(true));
    const { meta } = await dispatch(
      AddTranslationForCampaign({
        campaignID: Number(campaignID),
        languageID: formData.language.languageId,
      })
    );

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setButtonLoading(false));
      return;
    }
    dispatch(setButtonLoading(false));
    onAddSuccess();
  };

  return (
    <FormProvider {...methods}>
      <Stack spacing={2}>
        <AddCampaignTranslationLanguage />
        <Box display="flex" justifyContent={"flex-end"}>
          <ButtonLoader
            variant="contained"
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={buttonLoading}
          >
            <Typography>{t("save")}</Typography>
          </ButtonLoader>
        </Box>
      </Stack>
    </FormProvider>
  );
};

export default AddCampaignTranslation;
