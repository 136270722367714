import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import { requiredRules } from "shared/helpers/validators";
import BasicAutocomplete from "shared/ui/Autocomplete";
import { GetTranslationLanguages } from "store/slicers/campaignTranslations";

const languagesList = [
  {
    code: "hy",
    name: "Armenian",
    languageId: 59,
  },
  {
    code: "en",
    name: "English",
    languageId: 34,
  },
  {
    code: "ru",
    name: "Russian",
    languageId: 132,
  },
];

const AddCampaignTranslationLanguage = ({ name = "language" }) => {
  const [options, setOptions] = useState(languagesList);
  const [loading, setLoading] = useState(false);
  const [hasSeeMoreOption, setHasSeeMoreOption] = useState(true);
  const { watch } = useFormContext();
  const dispatch = useAsyncDispatch();

  const getMoreOptions = async () => {
    setLoading(true);
    const { payload } = await dispatch(GetTranslationLanguages());
    setOptions(payload);
    setLoading(false);
    setHasSeeMoreOption(false);
  };

  const initialFetch = useCallback(async () => {
    dispatch(GetTranslationLanguages());
  }, [dispatch]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  return (
    <BasicAutocomplete
      options={options}
      inputLabel={"Languages"}
      optionLabel="name"
      defaultValue={null}
      loading={loading}
      name={name}
      rules={requiredRules}
      getMoreOptions={getMoreOptions}
      hasSeeMoreOption={hasSeeMoreOption}
    />
  );
};

export default AddCampaignTranslationLanguage;
