import { Box, Button, Divider, Typography } from "@mui/material";
import { ECampaignSurveyType } from "pages/dashboard/CampaignDetails/questions/LeftSidebar/constants";
import { CampaignSurveyForms } from "pages/dashboard/CampaignDetails/questions/MainContent/constants";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import { ERequestStatus } from "store/enums/index.enum";
import {
  GetCampaignSurveyById,
  GetSurveysInitial,
  selectCampaignInfo,
  selectCampaignSurveys,
  selectSurveyInfo,
  UpdateSurvey,
} from "store/slicers/campaignDetail";
import Config from "./Config";
import { metricConfigable } from "pages/dashboard/CampaignDetails";

const answerResettable = [
  Number(ECampaignSurveyType.MultipleChoice),
  Number(ECampaignSurveyType.SingleChoice),
];

const defaultAnswer = {
  value: "",
  position: 0,
};

const withoutConfigQuestionTypes = [
  ECampaignSurveyType.Welcome,
  ECampaignSurveyType.Final,
  ECampaignSurveyType.Nps,
];

const QuestionContent = ({ item, setDrawerOpen }) => {
  const campaignInfo = useSelector(selectCampaignInfo);
  const surveyList = useSelector(selectCampaignSurveys);
  const dispatch = useAsyncDispatch();
  const surveyDetails = useSelector(selectSurveyInfo);
  const methods = useForm({ defaultValues: {} });
  const SurveyFormComp = useCallback(() => {
    const Comp = CampaignSurveyForms[item.type];
    return <Comp />;
  }, [item?.type]);

  const onSubmit = async (formData) => {
    const position = surveyList.find((i) => i.id === item.id).position;
    let answers = [];

    if (formData.answers?.length) {
      answers = formData?.answers?.map((answer) => {
        return {
          ...answer,
          newAnswer: !answer.id || false,
        };
      });
    }

    const config = {
      ...(surveyDetails.details.type ===
        Number(ECampaignSurveyType.Comment) && {
        commentConfig: formData?.commentConfig,
      }),
      ...(surveyDetails.details.type ===
        Number(ECampaignSurveyType.MultipleChoice) && {
        multipleConfig: formData?.multipleConfig,
      }),
      ...(metricConfigable.includes(surveyDetails.details.type) && {
        metricConfig: formData?.metricConfig,
      }),
    };

    const data = {
      campaignID: campaignInfo.id,
      title: formData.title,
      position,
      isRequired: formData.isRequired,
      buttonText: "Next",
      ...(formData.isRequired
        ? { skipText: "" }
        : { skipText: formData.skipText }),
      ...(surveyDetails.details.type === Number(ECampaignSurveyType.Comment)
        ? { commentText: formData.commentText }
        : { commentText: "" }),
      type: surveyDetails.details.type,
      answers,
      languageID: campaignInfo.defaultLanguageID,
      ...config,
    };

    const { meta } = await dispatch(
      UpdateSurvey({
        data: data,
        id: surveyDetails.details.id,
      })
    );

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    await Promise.all([
      dispatch(
        GetSurveysInitial({
          id: campaignInfo.id,
          language_id: campaignInfo.defaultLanguageID,
        })
      ),
      dispatch(
        GetCampaignSurveyById({
          id: item.id,
          language_id: campaignInfo?.defaultLanguageID,
        })
      ),
    ]);

    toast.success("Campaign Saved successfully");
    setDrawerOpen(false);
  };

  const handleResetForm = useCallback(() => {
    if (surveyDetails) {
      methods.reset({
        title: surveyDetails?.details?.title || "",
        answers:
          surveyDetails.details?.answers?.length &&
          answerResettable.includes(surveyDetails.details.type)
            ? surveyDetails?.details?.answers
            : [defaultAnswer],
        metricConfig: {
          metricLeftText:
            surveyDetails.details?.metricConfig?.metricLeftText || "",
          metricRightText:
            surveyDetails.details?.metricConfig?.metricRightText || "",
          customStartLength:
            surveyDetails.details?.metricConfig?.customStartLength.toString() ||
            "",
          customEndLength:
            surveyDetails.details?.metricConfig?.customEndLength.toString() ||
            "",
        },
        buttonText: surveyDetails.details?.buttonText,
        skipText: surveyDetails.details?.skipText,
        commentText: surveyDetails.details?.commentText,
        isRequired: surveyDetails.details?.isRequired,
        multipleConfig: {
          multipleType: surveyDetails.details?.multipleConfig?.multipleType,
          multipleExact:
            surveyDetails.details?.multipleConfig?.multipleExact?.toString() ||
            "",
          multipleMin:
            surveyDetails.details?.multipleConfig?.multipleMin?.toString() ||
            "",
          multipleMax:
            surveyDetails.details?.multipleConfig?.multipleMax?.toString() ||
            "",
        },
        commentConfig: {
          commentType: surveyDetails.details?.commentConfig?.commentType,
          commentMin:
            surveyDetails.details?.commentConfig?.commentMin?.toString() || "",
          commentMax:
            surveyDetails.details?.commentConfig?.commentMax?.toString() || "",
        },
      });
    }
  }, [methods, surveyDetails]);

  useEffect(() => {
    handleResetForm();
  }, [handleResetForm]);

  return (
    <FormProvider {...methods}>
      <Box sx={{ mb: 2 }}>
        <Typography sx={{ mb: 2 }} variant="h5">
          Answers
        </Typography>
        <SurveyFormComp />
      </Box>
      {!withoutConfigQuestionTypes.includes(item?.type?.toString()) && (
        <>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ mb: 2 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Configuration
            </Typography>
            <Config />
          </Box>
        </>
      )}

      <Button
        onClick={methods.handleSubmit(onSubmit)}
        sx={{ mt: 5 }}
        variant="outlined"
      >
        Save
      </Button>
    </FormProvider>
  );
};

export default QuestionContent;
