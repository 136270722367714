import {
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import { Box, styled } from "@mui/system";
import { IColumn } from "shared/ui/Table/constants";
import { ICampaignTranslation } from "store/interfaces/campaignTranslation";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 8,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}));

export const columns: IColumn[] = [
  {
    label: "Language",
    field: "language",
  },
  {
    label: "Visible to respondents",
    field: "visibleToRespondents",
    layout: (row: ICampaignTranslation) => {
      return <Typography>{row.visibleToRespondents ? "Yes" : "No"}</Typography>;
    },
  },
  {
    label: "Default",
    field: "isDefaultLanguage",
    layout: (row: ICampaignTranslation) => {
      return <Typography>{row.isDefaultLanguage ? "Yes" : "No"}</Typography>;
    },
  },
  {
    label: "Questions",
    field: "",
    layout: (row: ICampaignTranslation) => {
      return (
        <Typography>
          {row.completelyTranslatedSurveysCount} of {row.allSurveysCount}
        </Typography>
      );
    },
  },

  {
    label: "Percentage of translated surveys",
    field: "percentageOfTranslatedSurveys",
    layout: (row: ICampaignTranslation) => {
      return (
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Box width="100%">
            <BorderLinearProgress
              variant="determinate"
              value={row?.percentageOfTranslatedSurveys}
            />
          </Box>
          <Typography>{`${row.percentageOfTranslatedSurveys}%`}</Typography>
        </Box>
      );
    },
  },
];
