import ChatIcon from "@heroicons/react/24/outline/ChatBubbleBottomCenterTextIcon";
import CheckIcon from "@heroicons/react/24/outline/CheckIcon";
import HandRaisedIcon from "@heroicons/react/24/outline/HandRaisedIcon";
import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CircleStackIcon from "@heroicons/react/24/solid/CircleStackIcon";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import {
  CampaignSurveyColors,
  CampaignSurveyIcons,
  ECampaignSurveyType,
  SurveyTypeConfig,
} from "pages/dashboard/CampaignDetails/questions/LeftSidebar/constants";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RIGHT_SIDEBAR_WIDTH } from "resources/constants";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import DotsMenu from "shared/ui/DotsMenu";
import RightDrawer from "shared/ui/Drawer";
import { ERequestStatus } from "store/enums/index.enum";
import {
  CreateSurvey,
  GetCampaignById,
  GetCampaignSurveyById,
  GetSurveyLogic,
  GetSurveysInitial,
  RemoveCampaignSurvey,
  selectCampaignInfo,
  selectCampaignSurveys,
  selectSurveyInfo,
  setSelectedSurvey,
} from "store/slicers/campaignDetail";
import { setSidebarVisible } from "store/slicers/common";
import QuestionContent from "./QuestionContent";
import QuestionLogic from "./QuestionLogic";
import SharedDialog from "shared/ui/Dialog";
import QuestionPreview from "./Preview";

enum ESidebarTypes {
  Customize = "customize",
  Logic = "logic",
}

const Questions = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isPreviewModalOpen, setPreviewModalOpen] = useState(false);
  const [mode, setMode] = useState<ESidebarTypes>();
  const [activeRow, setActiveRow] = useState(null);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const addContentMenuOpen = Boolean(anchorEl);
  const campaignInfo = useSelector(selectCampaignInfo);
  const { id } = useParams();
  const dispatch = useAsyncDispatch();
  const surveyDetails = useSelector(selectSurveyInfo);
  const surveyList = useSelector(selectCampaignSurveys);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getOptionIcon = (type: number) => {
    const Comp = CampaignSurveyIcons[type];
    return (
      <Box mr={1} display="flex" alignItems="center">
        <Comp height={20} width={20} color="white" />
      </Box>
    );
  };

  const handleRemove = useCallback(
    async (id) => {
      const removeThunk = await dispatch(
        RemoveCampaignSurvey({
          id,
          language_id: campaignInfo.defaultLanguageID,
        })
      );
      if (removeThunk.meta.requestStatus !== ERequestStatus.FULFILLED) {
        return;
      }

      const { meta, payload } = await dispatch(
        GetSurveysInitial({
          id: campaignInfo.id,
          language_id: campaignInfo.defaultLanguageID,
        })
      );
      if (meta.requestStatus !== ERequestStatus.FULFILLED) {
        return;
      }
      dispatch(setSelectedSurvey(String(payload[0].id)));
    },
    [campaignInfo.defaultLanguageID, campaignInfo.id, dispatch]
  );

  const handleClickContentAdd = async (e) => {
    e.stopPropagation();
    const type = e.currentTarget.id;

    handleClose();

    const formData = {
      ...(SurveyTypeConfig[type] || {}),
      title: "",
      campaignID: String(campaignInfo.id),
      buttonText: "Next",
      type,
      selected: false,
      position:
        type === ECampaignSurveyType.Welcome
          ? 0
          : (surveyList[surveyList.length - 2]?.position || 1) + 1,
      isRequired: true,
    };

    const { meta, payload } = await dispatch(CreateSurvey(formData));
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      return;
    }

    await dispatch(
      GetSurveysInitial({
        id: campaignInfo.id,
        language_id: campaignInfo.defaultLanguageID,
      })
    );
    // setValue("answers", defaultAnswers);
    dispatch(setSelectedSurvey(String(payload.surveyId)));
  };

  const alreadyHasType = useCallback(
    (type: ECampaignSurveyType) => {
      return surveyList.find((i) => i.type === Number(type));
    },
    [surveyList]
  );

  const handleClickAction = useCallback((action: any, row: any) => {
    action?.onClick(row);
  }, []);

  const getDrawerTitle = () => {
    switch (mode) {
      case ESidebarTypes.Customize:
        return "Customize";
      case ESidebarTypes.Logic:
        return "Logic";
    }
  };

  const openPreviewModal = useCallback(
    async (id) => {
      const { payload } = await dispatch(GetCampaignSurveyById(id));
      let data = {
        details: {
          ...payload,
          title: payload?.title,
          button: payload?.buttonText,
        },
        template: { ...surveyDetails?.template },
      };
      if (
        payload?.type === Number(ECampaignSurveyType.Rating) ||
        payload?.type === Number(ECampaignSurveyType.Nps) ||
        payload?.type === Number(ECampaignSurveyType.ServiceQualityScore)
      ) {
        const {
          metricConfig: { customEndLength, customStartLength },
        } = payload;
        let answersArr = [];
        for (
          let i = Number(customStartLength);
          i <= Number(customEndLength);
          i++
        ) {
          answersArr.push({
            id: i,
            value: i,
          });
        }
        data = {
          details: {
            ...data?.details,
            metricConfig: payload?.metricConfig,
            answers: answersArr,
          },
          template: { ...data?.template },
        };
      }
      if (
        payload?.type === Number(ECampaignSurveyType.SingleChoice) ||
        payload?.type === Number(ECampaignSurveyType.MultipleChoice)
      ) {
        data = {
          details: {
            ...data?.details,
            answers: payload?.answers,
          },
          template: { ...data?.template },
        };
      }
      setActiveRow(data);
      setPreviewModalOpen(true);
    },
    [dispatch, surveyDetails?.template]
  );

  const getActions = useCallback(
    (rowData) => {
      return [
        {
          label: "Customize",
          onClick: () => {
            dispatch(
              GetCampaignSurveyById({
                id: rowData?.id,
                language_id: campaignInfo?.defaultLanguageID,
              })
            );
            setActiveRow(rowData);
            setMode(ESidebarTypes.Customize);
            setTimeout(() => {
              setDrawerOpen(true);
            }, 500);
          },
        },
        {
          label: "Logic",
          onClick: () => {
            dispatch(
              GetCampaignSurveyById({
                id: rowData?.id,
                language_id: campaignInfo?.defaultLanguageID,
              })
            );
            dispatch(
              GetSurveyLogic({
                id: rowData.id,
                languageID: campaignInfo?.defaultLanguageID,
              })
            );
            setActiveRow(rowData);
            setMode(ESidebarTypes.Logic);
            setTimeout(() => {
              setDrawerOpen(true);
            }, 500);
          },
        },
        {
          label: "Preview",
          onClick: () => {
            openPreviewModal(rowData.id);
          },
        },

        {
          label: "delete",
          onClick: () => {
            handleRemove(rowData.id);
          },
        },
      ];
    },
    [campaignInfo?.defaultLanguageID, dispatch, handleRemove, openPreviewModal]
  );

  const init = useCallback(async () => {
    const { payload } = await dispatch(GetCampaignById(Number(id)));

    await dispatch(
      GetSurveysInitial({
        id: Number(id),
        language_id: payload?.defaultLanguageID,
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    init();
  }, [init]);

  useLayoutEffect(() => {
    dispatch(setSidebarVisible(false));
  }, [dispatch]);

  return (
    <Box padding={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{campaignInfo?.name}</Typography>
        <Button
          variant="outlined"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          startIcon={<PlusIcon height={20} />}
        >
          Add content
        </Button>
      </Box>
      <Box>
        <Grid container spacing={3} mt={4} alignItems="stretch">
          {surveyList?.map((s, index) => {
            return (
              <Grid item xs={4} key={index}>
                <Card sx={{ height: "100%" }}>
                  <CardContent>
                    <Box
                      display="flex"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      width="100%"
                    >
                      <Typography fontWeight={700}>
                        Question {index + 1}
                      </Typography>
                      <Box display={"flex"} alignItems={"center"}>
                        <Box display={"flex"} alignItems={"center"}>
                          <Box
                            px={1}
                            display="flex"
                            justifyContent={"center"}
                            alignItems={"center"}
                            color="primary.secondary"
                            sx={{
                              backgroundColor: String(
                                CampaignSurveyColors[s.type]
                              ),
                              borderRadius: "4px",
                            }}
                          >
                            {getOptionIcon(s.type)}
                            <Typography color={"white"}>{index + 1}</Typography>
                          </Box>
                        </Box>
                        <DotsMenu
                          actions={getActions(s)}
                          onActionClick={handleClickAction}
                          row={s}
                        />
                      </Box>
                    </Box>
                    <Typography>{s.title}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Menu anchorEl={anchorEl} open={addContentMenuOpen} onClose={handleClose}>
        <Box p={2}>
          <Typography color="text.secondary">Questions</Typography>
        </Box>
        {!alreadyHasType(ECampaignSurveyType.Welcome) && (
          <MenuItem
            id={ECampaignSurveyType.Welcome}
            onClick={handleClickContentAdd}
          >
            <Box
              borderRadius={"4px"}
              display={"flex"}
              sx={{
                backgroundColor: CampaignSurveyColors[
                  ECampaignSurveyType.Welcome
                ] as string,
              }}
              alignItems={"center"}
              p={"2px"}
              mr={1}
            >
              <HandRaisedIcon height={20} color="white" width={40} />
            </Box>
            Welcome
          </MenuItem>
        )}
        <MenuItem
          id={ECampaignSurveyType.SingleChoice}
          onClick={handleClickContentAdd}
        >
          <Box
            borderRadius={"4px"}
            display={"flex"}
            sx={{
              backgroundColor: CampaignSurveyColors[
                ECampaignSurveyType.SingleChoice
              ] as string,
            }}
            alignItems={"center"}
            p={"2px"}
            mr={1}
          >
            <CircleStackIcon color="white" height={20} width={40} />
          </Box>
          Select One
        </MenuItem>
        <MenuItem
          id={ECampaignSurveyType.MultipleChoice}
          onClick={handleClickContentAdd}
        >
          <Box
            borderRadius={"4px"}
            display={"flex"}
            sx={{
              backgroundColor: CampaignSurveyColors[
                ECampaignSurveyType.MultipleChoice
              ] as string,
            }}
            alignItems={"center"}
            p={"2px"}
            mr={1}
          >
            <CheckIcon color="white" height={20} width={40} />
          </Box>
          Select Multiple
        </MenuItem>
        {!alreadyHasType(ECampaignSurveyType.Nps) && (
          <MenuItem
            id={ECampaignSurveyType.Nps}
            onClick={handleClickContentAdd}
          >
            <Box
              borderRadius={"4px"}
              display={"flex"}
              sx={{
                backgroundColor: CampaignSurveyColors[
                  ECampaignSurveyType.Nps
                ] as string,
              }}
              alignItems={"center"}
              p={"2px"}
              mr={1}
            >
              <ChartBarIcon color="white" height={20} width={40} />
            </Box>
            NPS
          </MenuItem>
        )}
        <MenuItem
          id={ECampaignSurveyType.Comment}
          onClick={handleClickContentAdd}
        >
          <Box
            borderRadius={"4px"}
            display={"flex"}
            sx={{
              backgroundColor: CampaignSurveyColors[
                ECampaignSurveyType.Comment
              ] as string,
            }}
            alignItems={"center"}
            p={"2px"}
            mr={1}
          >
            <ChatIcon color="white" height={20} width={40} />
          </Box>
          Comment
        </MenuItem>
        {!alreadyHasType(ECampaignSurveyType.Final) && (
          <MenuItem
            id={ECampaignSurveyType.Final}
            onClick={handleClickContentAdd}
          >
            <Box
              borderRadius={"4px"}
              display={"flex"}
              sx={{
                backgroundColor: CampaignSurveyColors[
                  ECampaignSurveyType.Final
                ] as string,
              }}
              alignItems={"center"}
              p={"2px"}
              mr={1}
            >
              <HandRaisedIcon height={20} color="white" width={40} />
            </Box>
            Final
          </MenuItem>
        )}
      </Menu>
      <RightDrawer
        open={isDrawerOpen}
        setOpen={setDrawerOpen}
        width={RIGHT_SIDEBAR_WIDTH}
        onClose={() => setDrawerOpen(false)}
        title={getDrawerTitle()}
      >
        <>
          {mode === ESidebarTypes.Customize ? (
            <QuestionContent item={activeRow} setDrawerOpen={setDrawerOpen} />
          ) : (
            <QuestionLogic setDrawerOpen={setDrawerOpen} />
          )}
        </>
      </RightDrawer>
      <SharedDialog
        hasActions={false}
        open={isPreviewModalOpen}
        setOpen={setPreviewModalOpen}
        fullScreen
        textConfig={{ title: "Preview" }}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiDialogContent-root": {
              padding: 0,
            },
          },
        }}
      >
        <QuestionPreview previewModalData={activeRow} />
      </SharedDialog>
    </Box>
  );
};

export default Questions;
