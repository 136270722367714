import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Button, SvgIcon, Switch, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { EServiceTypesPermissions } from "resources/permissions/permissions.enum";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import usePermission from "shared/helpers/hooks/usePermission";
import useTranslation from "shared/helpers/hooks/useTranslation";
import SharedDialog from "shared/ui/Dialog";
import RightDrawer from "shared/ui/Drawer";
import BasicTable from "shared/ui/Table";
import { ERequestStatus } from "store/enums/index.enum";
import { ICampaignTranslation } from "store/interfaces/campaignTranslation";
import {
  DeleteCampaignTranslation,
  GetCampaignTranslationGrid,
  GetTranslationLanguages,
  MakeCampaignTranslationVisible,
  selectCampaignTranslations,
} from "store/slicers/campaignTranslations";
import {
  selectLoadingState,
  setLoading,
  setTableLoading,
} from "store/slicers/common";
import AddCampaignTranslation from "./components/AddCampaignTranslation";
import QuestionTranslation from "./components/QuestionTranslation";
import { columns } from "./constants";

const CampaignTranslations = () => {
  const t = useTranslation();
  const [activeRow, setActiveRow] = useState<ICampaignTranslation>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isWarningOpen, setWarningOpen] = useState(false);
  const [isQuestionsModalOpen, setQuestionsModalOpen] = useState(false);
  const dispatch = useAsyncDispatch();
  const isLoading = useSelector(selectLoadingState);
  const params = useParams();

  const hasEditPermission = usePermission(EServiceTypesPermissions.Update);
  const hasCreatePermission = usePermission(EServiceTypesPermissions.Create);
  const hasDeletePermission = usePermission(EServiceTypesPermissions.Delete);

  const data = useSelector(selectCampaignTranslations);

  const refetchGrid = useCallback(async () => {
    dispatch(setTableLoading(true));
    await dispatch(GetCampaignTranslationGrid(params?.id));
    dispatch(setTableLoading(false));
  }, [dispatch, params?.id]);

  const handleEdit = async (row: ICampaignTranslation) => {
    setActiveRow(row);
    setQuestionsModalOpen(true);
  };

  const handleClose = () => {
    setActiveRow(undefined);
  };

  const onAddSuccess = () => {
    setQuestionsModalOpen(false);
    setDrawerOpen(false);
    refetchGrid();
  };

  const onUpdateSuccess = () => {
    refetchGrid();
  };

  const handleOpenWarning = (row: ICampaignTranslation) => {
    setActiveRow(row as ICampaignTranslation);
    setWarningOpen(true);
  };

  const handleDelete = async () => {
    if (!activeRow) {
      return;
    }
    dispatch(setLoading(true));
    const { meta } = await dispatch(
      DeleteCampaignTranslation({
        id: Number(params?.id),
        languageID: activeRow.languageID,
      })
    );
    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setLoading(false));
      return;
    }
    setActiveRow(undefined);
    await refetchGrid();
    dispatch(setLoading(false));
    toast.success("Service is deleted");
  };

  const getActions = (rowData: ICampaignTranslation) => {
    return [
      ...(hasEditPermission && !rowData?.isDefaultLanguage
        ? [
            {
              label: "edit",
              onClick: () => handleEdit(rowData),
            },
          ]
        : []),
      ...(hasDeletePermission
        ? [
            {
              label: "delete",
              onClick: () => handleOpenWarning(rowData),
            },
          ]
        : []),
    ];
  };

  const handleChangeState = useCallback(
    async (languageId: number, state: boolean) => {
      dispatch(setLoading(true));
      const { meta } = await dispatch(
        MakeCampaignTranslationVisible({ languageId, campaignId: params?.id })
      );
      if (meta.requestStatus !== ERequestStatus.FULFILLED) {
        dispatch(setLoading(false));
        return;
      }
      await refetchGrid();
      toast.success(
        `${t("survey")} ${t("is")} ${!state ? t("inactive") : t("active")}`
      );
      dispatch(setLoading(false));
    },
    [dispatch, params?.id, refetchGrid, t]
  );

  const translationColumns = useMemo(() => {
    return [
      ...columns,
      {
        label: "state",
        layout: (rowData: ICampaignTranslation) => {
          return (
            <Switch
              color="success"
              disabled={!hasEditPermission || isLoading}
              onChange={(e, checked) =>
                handleChangeState(rowData.languageID, checked)
              }
              checked={rowData.visibleToRespondents}
            />
          );
        },
      },
    ];
  }, [handleChangeState, hasEditPermission, isLoading]);

  const initialFetch = useCallback(async () => {
    await dispatch(setTableLoading(true));
    await dispatch(GetTranslationLanguages());
    const { meta } = await dispatch(GetCampaignTranslationGrid(params?.id));
    if (meta.requestStatus === ERequestStatus.FULFILLED) {
      await dispatch(setTableLoading(false));
    } else {
      await dispatch(setTableLoading(false));
    }
  }, [dispatch, params?.id]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  return (
    <Box p={4}>
      <Box display="flex" justifyContent="space-between">
        <Typography
          variant="h4"
          fontWeight={500}
          sx={{ color: "text.secondary" }}
        >
          {t("Translations")}
        </Typography>
        {hasCreatePermission && (
          <Button
            startIcon={
              <SvgIcon fontSize="small">
                <PlusIcon />
              </SvgIcon>
            }
            variant="contained"
            onClick={() => setDrawerOpen(true)}
          >
            {t("add")}
          </Button>
        )}
      </Box>
      <BasicTable
        toolbar={false}
        columns={translationColumns}
        data={data?.displayData || []}
        onChange={refetchGrid}
        getActions={getActions}
        enablePagination={false}
        sortable={false}
      />
      <RightDrawer
        open={isDrawerOpen}
        setOpen={setDrawerOpen}
        onClose={handleClose}
        title={t("add_campaign_translation")}
      >
        <AddCampaignTranslation
          campaignID={params?.id}
          onAddSuccess={onAddSuccess}
        />
      </RightDrawer>
      <SharedDialog
        open={isWarningOpen}
        setOpen={setWarningOpen}
        onSuccess={handleDelete}
        textConfig={{
          title: "warning",
          description: "delete_campaign_translation",
        }}
        minWidth="400px"
      />
      <SharedDialog
        maxWidth={"xl"}
        open={isQuestionsModalOpen}
        setOpen={setQuestionsModalOpen}
        hasActions={false}
        minWidth="400px"
        fullScreen
        textConfig={{
          title: "Questions",
        }}
      >
        <QuestionTranslation
          activeRow={activeRow}
          campaignID={params?.id}
          onAddSuccess={onUpdateSuccess}
        />
      </SharedDialog>
    </Box>
  );
};

export default CampaignTranslations;
