import {
  Card,
  CardContent,
  Divider,
  Grid,
  List,
  ListItemButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { ECampaignSurveyType } from "pages/dashboard/CampaignDetails/questions/LeftSidebar/constants";
import { ESurveyType } from "pages/dashboard/FeedBacks/constants";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "shared/helpers/hooks/useAsyncDispatch";
import useTranslation from "shared/helpers/hooks/useTranslation";
import { requiredRules } from "shared/helpers/validators";
import ButtonLoader from "shared/ui/ButtonLoader";
import BasicCheck from "shared/ui/Checkbox";
import InputField from "shared/ui/TextInput";
import { ERequestStatus } from "store/enums/index.enum";
import { IUpdateSurveyRequest } from "store/interfaces/campaignDetails";
import { ICampaignTranslation } from "store/interfaces/campaignTranslation";
import {
  GetCampaignSurveyById,
  selectCampaignInfo,
  selectCampaignSurveys,
  selectSurveyInfo,
  UpdateSurvey,
} from "store/slicers/campaignDetail";
import { selectTranslationLanguages } from "store/slicers/campaignTranslations";
import {
  selectButtonLoadingState,
  setButtonLoading,
} from "store/slicers/common";

const metricFormTypes = [
  ECampaignSurveyType.Rating,
  ECampaignSurveyType.Nps,
  ECampaignSurveyType.ServiceQualityScore,
  ECampaignSurveyType.CustomerEffortScore,
  ECampaignSurveyType.CustomerSatisfactionScore,
  ECampaignSurveyType.CustomStar,
];

const showAnswerTypes = [
  ECampaignSurveyType.SingleChoice,
  ECampaignSurveyType.MultipleChoice,
];

interface IFormValues {
  id: number;
  title: string;
  position: number;
  type: number;
  isRequired: boolean;
  buttonText: string;
  skipText: string;
  commentText: string;
  multipleConfig: null;
  commentConfig: null;
  metricConfig: null;
  answers: any[];
  questionNumber: number;
}

const defaultValues = {
  id: 0,
  title: "",
  position: 0,
  type: 0,
  isRequired: false,
  buttonText: "",
  skipText: "",
  commentText: "",
  multipleConfig: null,
  commentConfig: null,
  metricConfig: null,
  answers: [],
  questionNumber: 0,
};

const QuestionTranslation = ({
  activeRow,
  campaignID,
  onAddSuccess,
}: {
  activeRow: ICampaignTranslation;
  campaignID: string;
  onAddSuccess: () => void;
}) => {
  const t = useTranslation();
  const dispatch = useAsyncDispatch();
  const surveyList = useSelector(selectCampaignSurveys);
  const surveyInfo = useSelector(selectSurveyInfo)?.details;
  const languages = useSelector(selectTranslationLanguages);
  const btnLoading = useSelector(selectButtonLoadingState);
  const [selectedSurveyId, setSelectedSurveyId] = useState("");
  const [loading, setLoading] = useState(false);
  const methods = useForm<IFormValues>({ defaultValues });
  const campaignInfo = useSelector(selectCampaignInfo);

  const { reset, control } = methods;

  const formValues = useWatch({ control });

  const selectQuestion = useCallback(
    async (id) => {
      setLoading(true);
      setSelectedSurveyId(String(id));
      reset(defaultValues);
      const ln = languages.find((l) => l.code === activeRow.languageCode);
      const { meta, payload } = await dispatch(
        GetCampaignSurveyById({
          language_id: ln.languageId,
          id,
        })
      );
      if (meta.requestStatus !== ERequestStatus.FULFILLED) {
        setLoading(false);
        return;
      }
      setLoading(false);
      reset(payload);
    },
    [activeRow.languageCode, dispatch, languages, reset]
  );

  const onSubmit = async (formData: IFormValues) => {
    const { id, ...data } = formData;
    await dispatch(setButtonLoading(true));
    const ln = languages.find((l) => l.code === activeRow.languageCode);
    const { meta } = await dispatch(
      UpdateSurvey({
        data: {
          ...data,
          campaignID: Number(campaignID),
          languageID: ln.languageId,
        },
        id,
      })
    );

    if (meta.requestStatus !== ERequestStatus.FULFILLED) {
      dispatch(setButtonLoading(false));
      return;
    }
    dispatch(setButtonLoading(false));
    toast.success(t("success"));
    onAddSuccess();
  };

  const defaultLanguage = useMemo(() => {
    return languages.find(
      (l) => l.languageId === campaignInfo.defaultLanguageID
    );
  }, [campaignInfo.defaultLanguageID, languages]);

  useEffect(() => {
    selectQuestion(surveyList?.[0]?.id);
  }, [selectQuestion, surveyList]);

  return (
    <FormProvider {...methods}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <List sx={{ position: "sticky", top: 0 }}>
            {surveyList?.map((s, index) => {
              return (
                <ListItemButton
                  key={index}
                  onClick={() => selectQuestion(s.id)}
                  selected={Number(s.id) === Number(selectedSurveyId)}
                >
                  <Typography>
                    {index + 1}. {s.title}
                  </Typography>
                </ListItemButton>
              );
            })}
          </List>
        </Grid>
        <Grid item xs={8}>
          {loading ? (
            <Skeleton
              animation="wave"
              height={400}
              width={"100%"}
              sx={{ transform: "scale(1, 0.90)" }}
            />
          ) : (
            <Box mt={2}>
              <Card>
                <CardContent>
                  <Stack direction="column" spacing={2}>
                    <>
                      <Box sx={{ border: "1px solid", borderRadius: "4px" }}>
                        <Box sx={{ p: 1 }}>
                          <Typography>{t("general")}</Typography>
                        </Box>
                        <Box sx={{ borderTop: "1px solid" }}>
                          <Box sx={{ display: "flex" }}>
                            <Box
                              flex={1}
                              sx={{
                                borderRight: "1px solid",
                                p: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                                backgroundColor: "divider",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography>{t("base_language")}</Typography>
                                <Typography>
                                  {defaultLanguage?.name}(
                                  {defaultLanguage?.code})
                                </Typography>
                              </Box>
                              <Typography fontWeight="bold">
                                {t("question_title")}
                              </Typography>
                              <Typography>{surveyInfo?.title}</Typography>
                            </Box>
                            <Box
                              flex={1}
                              sx={{
                                p: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: 0.5,
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography>
                                  {t("translation_language")}
                                </Typography>
                                <Typography>
                                  {activeRow?.language}(
                                  {activeRow?.languageCode})
                                </Typography>
                              </Box>
                              <Typography fontWeight="bold">
                                {t("question_title")}
                              </Typography>
                              <InputField
                                name="title"
                                label="question_title"
                                rules={requiredRules}
                              />
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </>
                    {showAnswerTypes.includes(String(formValues.type)) && (
                      <>
                        <Box sx={{ border: "1px solid", borderRadius: "4px" }}>
                          <Box sx={{ p: 1 }}>
                            <Typography>{t("answers")}</Typography>
                          </Box>
                          <Box
                            sx={{
                              borderTop: "1px solid",
                            }}
                          >
                            {formValues.answers?.length ? (
                              formValues.answers.map((i, index) => {
                                return (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      "&:not(:first-of-type):not(:last-of-type)":
                                        {
                                          borderBottom: "1px solid",
                                          borderTop: "1px solid",
                                        },
                                    }}
                                    key={index}
                                  >
                                    <Box
                                      flex={1}
                                      sx={{
                                        borderRight: "1px solid",
                                        p: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 0.5,
                                        backgroundColor: "divider",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography>
                                          {t("base_language")}
                                        </Typography>
                                        <Typography>
                                          {defaultLanguage?.name}(
                                          {defaultLanguage?.code})
                                        </Typography>
                                      </Box>
                                      <Typography fontWeight="bold">
                                        {t("option")} {index + 1}
                                      </Typography>
                                      <Typography>
                                        {surveyInfo?.answers[index].value}
                                      </Typography>
                                    </Box>
                                    <Box
                                      flex={1}
                                      sx={{
                                        p: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 0.5,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <Typography>
                                          {t("translation_language")}
                                        </Typography>
                                        <Typography>
                                          {activeRow?.language}(
                                          {activeRow?.languageCode})
                                        </Typography>
                                      </Box>
                                      <Typography fontWeight="bold">
                                        {t("option")} {index + 1}
                                      </Typography>
                                      <InputField
                                        name={`answers[${index}].value`}
                                        label={`${index + 1} option`}
                                        rules={requiredRules}
                                      />
                                    </Box>
                                  </Box>
                                );
                              })
                            ) : (
                              <></>
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                    {formValues.type !== Number(ECampaignSurveyType.Final) && (
                      <>
                        <Box sx={{ border: "1px solid", borderRadius: "4px" }}>
                          <Box sx={{ p: 1 }}>
                            <Typography>{t("settings")}</Typography>
                          </Box>
                          <Box sx={{ borderTop: "1px solid" }}>
                            <Box
                              sx={{
                                display: "flex",
                                borderBottom: "1px solid",
                              }}
                            >
                              <Box
                                flex={1}
                                sx={{
                                  borderRight: "1px solid",
                                  p: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 0.5,
                                  backgroundColor: "divider",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>{t("base_language")}</Typography>
                                  <Typography>
                                    {defaultLanguage?.name}(
                                    {defaultLanguage?.code})
                                  </Typography>
                                </Box>
                                <Typography fontWeight="bold">
                                  {t("button_text")}
                                </Typography>
                                <Typography>
                                  {surveyInfo?.buttonText}
                                </Typography>
                              </Box>
                              <Box
                                flex={1}
                                sx={{
                                  p: 1,
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: 0.5,
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography>
                                    {t("translation_language")}
                                  </Typography>
                                  <Typography>
                                    {activeRow?.language}(
                                    {activeRow?.languageCode})
                                  </Typography>
                                </Box>
                                <Typography fontWeight="bold">
                                  {t("button_text")}
                                </Typography>
                                <InputField
                                  name="buttonText"
                                  label="button_text"
                                  rules={requiredRules}
                                />
                              </Box>
                            </Box>
                            {!formValues.isRequired && (
                              <Box
                                sx={{
                                  display: "flex",
                                }}
                              >
                                <Box
                                  flex={1}
                                  sx={{
                                    borderRight: "1px solid",
                                    p: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0.5,
                                    backgroundColor: "divider",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography>
                                      {t("base_language")}
                                    </Typography>
                                    <Typography>
                                      {defaultLanguage?.name}(
                                      {defaultLanguage?.code})
                                    </Typography>
                                  </Box>
                                  <Typography fontWeight="bold">
                                    {t("skip_text")}
                                  </Typography>
                                  <Typography>
                                    {surveyInfo?.skipText}
                                  </Typography>
                                </Box>
                                <Box
                                  flex={1}
                                  sx={{
                                    p: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 0.5,
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <Typography>
                                      {t("translation_language")}
                                    </Typography>
                                    <Typography>
                                      {activeRow?.language}(
                                      {activeRow?.languageCode})
                                    </Typography>
                                  </Box>
                                  <Typography fontWeight="bold">
                                    {t("skip_text")}
                                  </Typography>
                                  <InputField
                                    name="skipText"
                                    label="Skip Text"
                                    rules={requiredRules}
                                  />
                                </Box>
                              </Box>
                            )}
                            {metricFormTypes.includes(
                              String(formValues.type)
                            ) && (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    borderBottom: "1px solid",
                                    borderTop: "1px solid",
                                  }}
                                >
                                  <Box
                                    flex={1}
                                    sx={{
                                      borderRight: "1px solid",
                                      p: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0.5,
                                      backgroundColor: "divider",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        {t("base_language")}
                                      </Typography>
                                      <Typography>
                                        {defaultLanguage?.name}(
                                        {defaultLanguage?.code})
                                      </Typography>
                                    </Box>
                                    <Typography fontWeight="bold">
                                      {t("not_likely")}
                                    </Typography>
                                    <Typography>
                                      {surveyInfo?.metricConfig?.metricLeftText}
                                    </Typography>
                                  </Box>
                                  <Box
                                    flex={1}
                                    sx={{
                                      p: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0.5,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        {t("translation_language")}
                                      </Typography>
                                      <Typography>
                                        {activeRow?.language}(
                                        {activeRow?.languageCode})
                                      </Typography>
                                    </Box>
                                    <Typography fontWeight="bold">
                                      {t("not_likely")}
                                    </Typography>
                                    <InputField
                                      name="metricConfig.metricLeftText"
                                      placeholder={"Type left text here"}
                                      label="Not Likely"
                                    />
                                  </Box>
                                </Box>
                                <Box sx={{ display: "flex" }}>
                                  <Box
                                    flex={1}
                                    sx={{
                                      borderRight: "1px solid",
                                      p: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0.5,
                                      backgroundColor: "divider",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        {t("base_language")}
                                      </Typography>
                                      <Typography>
                                        {defaultLanguage?.name}(
                                        {defaultLanguage?.code})
                                      </Typography>
                                    </Box>
                                    <Typography fontWeight="bold">
                                      {t("more_likely")}
                                    </Typography>
                                    <Typography>
                                      {
                                        surveyInfo?.metricConfig
                                          ?.metricRightText
                                      }
                                    </Typography>
                                  </Box>
                                  <Box
                                    flex={1}
                                    sx={{
                                      p: 1,
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: 0.5,
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Typography>
                                        {t("translation_language")}
                                      </Typography>
                                      <Typography>
                                        {activeRow?.language}(
                                        {activeRow?.languageCode})
                                      </Typography>
                                    </Box>
                                    <Typography fontWeight="bold">
                                      {t("more_likely")}
                                    </Typography>
                                    <InputField
                                      name="metricConfig.metricRightText"
                                      placeholder={"Type right text here"}
                                      label="Likely"
                                    />
                                  </Box>
                                </Box>
                              </>
                            )}
                          </Box>
                        </Box>
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
              <Box sx={{ my: 2 }}>
                <BasicCheck name={"completed"} label={"Completed"} />
              </Box>
              <Box display="flex" justifyContent={"flex-end"}>
                <ButtonLoader
                  variant="contained"
                  onClick={methods.handleSubmit(onSubmit)}
                  isLoading={btnLoading}
                >
                  <Typography>{t("save")}</Typography>
                </ButtonLoader>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default QuestionTranslation;
